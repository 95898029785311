export const USAGE_REPORT_HEADER = "Usage Report";
export const RR_PROGRESS_REPORT_HEADER = "RR Progress Report";
export const DOWNLOAD_BUTTON = "Download Report";
export const DOWNLOADING = "Downloading...";
export const VIEW_BUTTON = "View";
export const REPORT_TYPE_PLACEHOLDER = "Select Report Type";
export const REPORT_TYPE = "Excel";
export const REPORT_OPTION = [
  // {
  //   value: "Usage Report",
  //   label: "Usage Report",
  // },
  {
    value: "RR Progress Report",
    label: "RR Progress Report",
  },
  // {
  //   value: "DailyOffshoreOnshoreDemandReport",
  //   label: "Daily Offshore-Onshore Demand Report",
  // },
  {
    value: "RRTalentMapping",
    label: "RR-Talent Mapping",
  },
  // {
  //   value: "RRActivityAgingReport",
  //   label: "RR Activity Aging Report",
  // },
  // {
  //   value: "LaunchPadExitReport",
  //   label: "LaunchPad Exit Report",
  // },
  // {
  //   value: "LaunchPadTalentStatusReport",
  //   label: "LaunchPad Talent Status Report",
  // },
];
